<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>Summe der Einzelbeträge</v-list-item-content>
      <v-list-item-action><CurrencyValue v-model="sum" /> </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>Rundungsdifferenz</v-list-item-content>
      <v-list-item-action
        ><CurrencyValue v-model="difference" />
      </v-list-item-action>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>Rechnungsbetrag</v-list-item-content>
      <v-list-item-action>
        <CurrencyValue v-if="readonly" v-model="modelValue.amount" />
        <CurrencyInput v-else v-model="modelValue.amount" />
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="check">
      <v-alert type="error">
        <strong>Der Rechnungsbetrag ist nicht vollständig aufgeteilt.</strong>
        Die maximale Toleranz beträgt <CurrencyValue v-model="tolerance" />.
      </v-alert>
    </v-list-item>
  </v-list>
</template>
<script>
import { defineComponent } from "vue";
import CurrencyInput from "@/components/CurrencyInput.vue";
import CurrencyValue from "common/components/CurrencyValue.vue";

export default defineComponent({
  components: {
    CurrencyInput,
    CurrencyValue,
  },
  props: {
    readonly: { type: Boolean, default: false },
    value: { type: Object },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  computed: {
    difference() {
      return this.modelValue ? this.modelValue.amount - this.sum : 0;
    },
    sum() {
      if (!this.modelValue) return 0;
      const details = this.modelValue.details;
      let result = 0;
      for (let i = 0; i < details.length; ++i) {
        result += details[i].amount;
      }
      return result;
    },
    check() {
      return Math.abs(this.difference) > this.tolerance;
    },
    tolerance() {
      return 0.02 * this.modelValue.details.length;
    },
  },
  watch: {
    difference() {
      if (this.check) {
        this.$emit("updateTolerance", false);
      } else {
        this.$emit("updateTolerance", true);
      }
    },
    value() {
      this.modelValue = this.value;
    },
  },
});
</script>
